import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { ScreenShare, RecentActors, ImportExport } from "@material-ui/icons";
import { formatMessage, MainMenuContribution, withModulesManager } from "@openimis/fe-core";

// const SERVICE_PROVIDER_MAIN_MENU_CONTRIBUTION_KEY = "serviceProvider.MainMenu";

class ServiceProviderMainMenu extends Component {
  render() {
    const { intl, rights , modulesManager} = this.props;
    let entries = [];
    //push add service provider submenu to payroll managenent menu items
    // entries.push({
    //   text: "Service Providers List",
    //   icon: <RecentActors />,
    //   route: "/" + modulesManager.getRef("serviceProvider.route.service_providers"),
    // });

    // entries.push(
    //   { text: formatMessage(this.props.intl, "tools", "menu.registers"), icon: <ImportExport />, route: "/tools/registers" }
    // );
    

    if (!entries.length) return null;
    return (
      <MainMenuContribution
        {...this.props}
        header={formatMessage(intl, "serviceProvider", "mainMenu")}
        icon={<ScreenShare />}
        entries={entries}
      />
    );

  }
}


export default withModulesManager(injectIntl(ServiceProviderMainMenu));