import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useTranslations, useGraphqlQuery } from "@openimis/fe-core";

export default function InsureeBenefitsPicker(props) {
  const [searchString, setSearchString] = useState();
  const { formatMessage } = useTranslations("benefits");

  const { isLoading, data, error } = useGraphqlQuery(
    `
    query {
      insureeBenefits{
        id
        code
        benefits
        uuid
      }
    }
  `,
    { str: searchString },
  );
  return (
    <Autocomplete
      multiple
      options={data?.insureeBenefits?.map((b) => b) ?? []}
      getOptionLabel={(o) => o?.benefits}
      disableCloseOnSelect
      isLoading={isLoading}
      onChange={(option, value) => props.onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={formatMessage("Insuree.benefits")}
          placeholder={formatMessage("Insuree.benefits")}
        />
      )}
    />
  );
}
