import React from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
    ControlledField, PublishedComponent, FormPanel,
    TextInput, TextAreaInput,
    withModulesManager,
} from "@openimis/fe-core";
import { Grid, Paper} from "@material-ui/core";

//Sets the styles to be displayed on the page....
const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});

//this class is called when user ondoubleclick on any service provider details....
//It populates the form and set readOnly to true so that no one can edit the fields...
class ServiceProviderMasterPanel extends FormPanel {
   
    render() {
        const { classes, edited, reset, readOnly = false } = this.props;
        return (
            //Fields to display in the form...
            //Page is a style component that add border frame to the form....
            <Grid container className={classes.item} align="center" justify="center" direction="column" className={classes.fullHeight}>
                <Paper className={classes.paper}>
                <Grid container xs={12}  className={classes.item}>

                 <ControlledField module="serviceProvider" id="ServiceProvider.name" field={
                    <Grid item xs={2} className={classes.item}>
                        <TextInput
                            module="serviceProvider" 
                            label="ServiceProviderForm.name"
                            name="name"
                            value={edited.name}
                            required={true}
                            readOnly = {true}
                            onChange={(v, s) => this.updateAttribute("name", v)}
                         
                        />
                    </Grid>  

                } />
               
                 <ControlledField module="serviceProvider" id="ServiceProvider.code" field={

                    <Grid item xs={2} className={classes.item}>
                        <TextInput
                            module="serviceProvider" 
                            label="ServiceProviderForm.code"
                            name="code"
                            value={edited.code}
                            readOnly={true}
                            required={true}
                            readOnly = {true}
                            onChange={(v, s) => this.updateAttribute("code", v)}
                          
                        />
                    </Grid>
                } />

                <ControlledField module="serviceProvider" id="ServiceProvider.legalForm" field={
                    <Grid item xs={2} className={classes.item}>
                        <PublishedComponent
                            pubRef="serviceProvider.ServiceProviderLegalFormPicker"
                            value={!!edited.legalForm ? edited.legalForm.code : null}
                            reset={reset}
                            readOnly={true}
                            required={true}
                            onChange={(v, s) => this.updateAttribute('legalForm', v)}
                        />
                    </Grid>
                } />
                <ControlledField module="serviceProvider" id="ServiceProvider.level" field={
                    <Grid item xs={2} className={classes.item}>
                        <PublishedComponent
                            pubRef="serviceProvider.ServiceProviderLevelPicker"
                            value={!!edited.level ? edited.level.code : null}
                            reset={reset}
                            readOnly={true}
                            required={true}
                            onChange={(v, s) => this.updateAttribute("level", v )}
                        />
                    </Grid>
                } />
                               
                <ControlledField module="serviceProvider" id="ServiceProvider.subLevel" field={
                    <Grid item xs={2} className={classes.item}>
                        <PublishedComponent
                            pubRef="serviceProvider.ServiceProviderSubLevelPicker"
                            value={ !!edited.subLevel ? edited.subLevel.code : null}
                            withNull={true}
                            readOnly={true}
                            onChange={(v, s) => this.updateAttribute('subLevel', v )}
                        />
                    </Grid>
                } />
                
                
                <ControlledField module="serviceProvider" id="ServiceProvider.accountCode" field={
                    <Grid item xs={2} className={classes.item}>
                        <TextInput
                            module="serviceProvider" 
                            label="ServiceProviderForm.accountCode"
                            name="accountCode"
                            value={edited.accountCode}
                            readOnly={true}
                            required={true}
                            onChange={(v, s) => this.updateAttribute("accountCode", v)}
                          
                        />
                    </Grid>
                } />

                <ControlledField module="serviceProvider" id="ServiceProvider.address" field={
                    <Grid item xs={3} className={classes.item}>
                        <TextInput
                            module="serviceProvider" 
                            label="ServiceProviderForm.address"
                            name="address"
                            value={edited.address}
                            readOnly={true}
                            required={true}
                            onChange={(v, s) => this.updateAttribute("address", v)}
                        
                        />
                    </Grid>
                } />

                
                <ControlledField module="serviceProvider" id="ServiceProvider.phoneNumber" field={
                    <Grid item xs={3} className={classes.item}>
                        <TextInput
                            module="serviceProvider" 
                            label="ServiceProviderForm.phoneNumber"
                            name="phoneNumber"
                            value={edited.phoneNumber}
                            readOnly={true}
                            onChange={(v, s) => this.updateAttribute("phoneNumber", v)}
                        />
                    </Grid>
                } />
                <ControlledField module="serviceProvider" id="ServiceProvider.email" field={
                    <Grid item xs={3} className={classes.item}>
                        <TextInput
                            module="serviceProvider" 
                            label="ServiceProviderForm.email"
                            name="email"
                            value={edited.email}
                            readOnly={true}
                            onChange={(v, s) => this.updateAttribute("email", v)}
                        />
                    </Grid>
                } />
                <ControlledField module="serviceProvider" id="ServiceProvider.fax" field={
                    <Grid item xs={3} className={classes.item}>
                        <TextInput
                            module="serviceProvider" 
                            label="ServiceProviderForm.fax"
                            name="fax"
                            value={edited.fax}
                            readOnly={true}
                            onChange={(v, s) => this.updateAttribute("fax", v)}
                        />
                    </Grid>
                } />
                
                </Grid>

                </Paper>  

            </Grid>
        )
    }
}

//This section exports the class with all the styling adopted in the implemetations..
export default withModulesManager(withTheme(withStyles(styles)(ServiceProviderMasterPanel)))

