import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';
import { fetchServiceProviderStr, fetchServiceProviderStrs } from "../actions";
import { formatMessage, AutoSuggestion, withModulesManager } from "@openimis/fe-core";
import { ServiceProviderLabel } from "../utils";
import _ from "lodash";
import {Grid } from "@material-ui/core";
import { PublishedComponent } from "@openimis/fe-core";

const styles = theme => ({
    page: theme.page
});

class ServiceProviderPicker extends Component {

    state = {
        ServiceProviders: [],
        edited:{}
    }

    constructor(props) {
        super(props);
        this.selectThreshold = props.modulesManager.getConf("fe-serviceProvider", "ServiceProviderPicker.selectThreshold", 10);
    }

    componentDidMount() {
        this.setState({ ServiceProviders: this.props.ServiceProviders });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.ServiceProviders, this.props.ServiceProviders)) {
            this.setState({ ServiceProviders: this.props.ServiceProviders })
        }
    }

    getSuggestions = str => !!str &&
        str.length >= this.props.modulesManager.getConf("fe-serviceProvider", "schemesMinCharLookup", 2) &&
        this.props.fetchServiceProviderStrs(this.props.modulesManager, str);

    debouncedGetSuggestion = _.debounce(
        this.getSuggestions,
        this.props.modulesManager.getConf("fe-serviceProvider", "debounceTime", 800)
    )

    onSuggestionSelected = v => this.props.onChange(v, ServiceProviderLabel(v));

    onClear = () => {
        this.setState(
            { ServiceProviders: [] },
            e => this.onSuggestionSelected(null)
        );
    }

    updateAttribute = (k,v)=>{
        this.setState((state) => (
            {
                edited: {...state.edited, [k]:v}
            }),
        )
    }

    render() {
        const { intl, classes, value, reset,  withLabel = true, label, overview=false, withPlaceholder, placeholder = null,
            readOnly = false, required = false, withNull = true, nullLabel = null, schemeType=null} = this.props;
        const { ServiceProviders, edited } = this.state;

        return(
            <Grid container>
                <Grid item xs={12} className={classes.item}>
                    <AutoSuggestion
                        module="serviceProvider"
                        items={ServiceProviders}
                        label={!!withLabel && (label || formatMessage(intl, "serviceProvider", "ServiceProviderPicker.label"))}
                        placeholder={!!withPlaceholder ? placeholder || formatMessage(intl, "serviceProvider", `ServiceProvider${serviceProvider}Picker.placehoder`) : null}
                        lookup={ServiceProviderLabel}
                        onClear={this.onClear}
                        getSuggestions={this.debouncedGetSuggestion}
                        renderSuggestion={a => <span>{ServiceProviderLabel(a)}</span>}
                        getSuggestionValue={ServiceProviderLabel}
                        onSuggestionSelected={this.onSuggestionSelected}
                        value={value}
                        reset={reset}
                        readOnly={readOnly}
                        required={required}
                        selectThreshold={this.selectThreshold}
                        withNull={withNull}
                        nullLabel={nullLabel || formatMessage(intl, "serviceProvider", "serviceProvider.ServiceProviderPicker.null")}
                    />
            </Grid>
        </Grid>
        )
    }
}

const mapStateToProps = state => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    ServiceProviders: state.serviceProvider.ServiceProviders,
    edited: state.edited,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ fetchServiceProviderStrs }, dispatch);
};

export default withModulesManager(connect(mapStateToProps, mapDispatchToProps)(injectIntl(withTheme(withStyles(styles)(ServiceProviderPicker)))));