import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
  Divider,
  IconButton,
  Tooltip,
  Typography,
  Fab,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import clsx from "clsx";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {
  People as PeopleIcon,
  Tab as TabIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import {
  withModulesManager,
  formatMessageWithValues,
  formatDateFromISO,
  formatMessage,
  withHistory,
  withTooltip,
  historyPush,
  coreConfirm,
  journalize,
  Searcher,
} from "@openimis/fe-core";
import EnquiryDialog from "./EnquiryDialog";
import { RIGHT_SCHEME_DELETE } from "../constants";
import { fetchSchemeSummaries, deleteScheme, fetchScheme } from "../actions";

import SchemeFilter from "./SchemeFilter";
import { SchemeLabel } from "../utils";
import AddIcon from "@material-ui/icons/Add";
import { RIGHT_SCHEME_ADD } from "../constants";

const styles = (theme) => ({
  paper: {
    ...theme.paper.paper,
    margin: 0,
  },
  paperHeader: {
    ...theme.paper.header,
    padding: 10,
  },
  tableTitle: theme.table.title,
  fab: theme.fab,
  button: {
    margin: theme.spacing(1),
  },
  item: {
    padding: theme.spacing(1),
  },
});

const SCHEME_SEARCHER_CONTRIBUTION_KEY = "programs.SchemeSearcher";

class SchemeSearcher extends Component {
  state = {
    open: false,
    chfid: null,
    confirmedAction: null,
    reset: 0,
  };

  constructor(props) {
    super(props);
    this.rowsPerPageOptions = props.modulesManager.getConf(
      "fe-scheme",
      "schemeFilter.rowsPerPageOptions",
      [10, 20, 50, 100]
    );
    this.defaultPageSize = props.modulesManager.getConf(
      "fe-scheme",
      "schemeFilter.defaultPageSize",
      10
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
      this.setState({ reset: this.state.reset + 1 });
    } else if (!prevProps.confirmed && this.props.confirmed) {
      this.state.confirmedAction();
    }
  }

  onAdd = () => {
    historyPush(
      this.props.modulesManager,
      this.props.history,
      "programs.route.addprograms"
    );
  };

  fetch = (prms) => {
    this.props.fetchSchemeSummaries(this.props.modulesManager, prms);
  };

  rowIdentifier = (r) => r.uuid;

  filtersToQueryParams = (state) => {
    let prms = Object.keys(state.filters)
      .filter((f) => !!state.filters[f]["filter"])
      .map((f) => state.filters[f]["filter"]);
    prms.push(`first: ${state.pageSize}`);
    if (!!state.afterCursor) {
      prms.push(`after: "${state.afterCursor}"`);
    }
    if (!!state.beforeCursor) {
      prms.push(`before: "${state.beforeCursor}"`);
    }
    if (!!state.orderBy) {
      prms.push(`orderBy: ["${state.orderBy}"]`);
    }
    return prms;
  };

  headers = (filters) => {
    var h = [
      "scheme.schemeSummaries.code",
      "scheme.schemeSummaries.name",
      "scheme.schemeSummaries.schemeType",
      "scheme.schemeSummaries.status",
      "Action",
    ];
    return h;
  };

  sorts = (filters) => {
    var results = [
      ["name", true],
      ["code", true],
      ["schemeType", true],
      ["status", true],
    ];
    return results;
  };

  confirmDelete = (i) => {
    let programs = null;
    let scheme = this.props.schemes?.filter((schm) => schm.uuid === i.uuid);
    if (scheme[0].payrollcycleSet.edges.length > 0) {
      programs = scheme[0].payrollcycleSet;
    }
    let confirmedAction = () =>
      programs
        ? null
        : this.props.deleteScheme(
            this.props.modulesManager,
            !!i ? i.uuid : null,
            i,
            formatMessageWithValues(
              this.props.intl,
              "programs",
              "deleteScheme.mutationLabel",
              { label: SchemeLabel(i) }
            )
          );
    let confirm = (e) =>
      this.props.coreConfirm(
        formatMessageWithValues(
          this.props.intl,
          "programs",
          programs ? "schema.delete.first" : "deleteSchemeDialog.title",
          { label: SchemeLabel(i) }
        ),
        formatMessageWithValues(
          this.props.intl,
          "programs",
          programs ? "schema.delete.first" : "deleteSchemeDialog.message",
          {
            label: SchemeLabel(i),
          }
        )
      );
    this.setState({ confirmedAction }, confirm);
  };

  deleteSchemeAction = (i) =>
    !!i.validityTo ? null : (
      <Tooltip
        title={formatMessage(
          this.props.intl,
          "programs",
          "schemeSummaries.deleteScheme.tooltip"
        )}
      >
        <IconButton
          onClick={(e) => !i.clientMutationId && this.confirmDelete(i)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    );

  itemFormatters = (filters) => {
    var formatters = [
      (scheme) => scheme.code,
      (scheme) => scheme.name,
      (scheme) => scheme.schemeType,
      (scheme) => scheme.status,
      (scheme) => this.deleteSchemeAction(scheme),
    ];
    return formatters;
  };

  rowDisabled = (selection, i) => !!i.validityTo;
  rowLocked = (selection, i) => !!i.clientMutationId;

  render() {
    const {
      intl,
      schemes,
      schemesPageInfo,
      fetchingSchemes,
      fetchedSchemes,
      errorSchemes,
      filterPaneContributionsKey,
      cacheFiltersKey,
      onDoubleClick,
      rights,
      readOnly,
      classes,
    } = this.props;

    let count = schemesPageInfo.totalCount;

    let actions =
      !!readOnly || !rights.includes(RIGHT_SCHEME_ADD)
        ? []
        : [
            {
              button: (
                <IconButton onClick={this.onAdd}>
                  <AddIcon />
                </IconButton>
              ),
              tooltip: formatMessage(
                intl,
                "programs",
                "action.AddScheme.tooltip"
              ),
            },
          ];

    return (
      <Fragment>
        {/* <Grid container alignItems="center" spacing={3}>
                        {actions.map((a, idx) => {
                            return (
                                <Grid item key={`form-action-${idx}`}>
                                    {withTooltip(a.button, a.tooltip)}
                                </Grid>
                            );
                        })}
                    </Grid> */}
        <Searcher
          module="programs"
          cacheFiltersKey={cacheFiltersKey}
          filterPaneContributionsKey={filterPaneContributionsKey}
          items={schemes}
          itemsPageInfo={schemesPageInfo}
          fetchingItems={fetchingSchemes}
          fetchedItems={fetchedSchemes}
          errorItems={errorSchemes}
          contributionKey={SCHEME_SEARCHER_CONTRIBUTION_KEY}
          tableTitle={formatMessageWithValues(
            intl,
            "programs",
            "schemeSummaries",
            { count }
          )}
          rowsPerPageOptions={this.rowsPerPageOptions}
          defaultPageSize={this.defaultPageSize}
          fetch={this.fetch}
          rowIdentifier={this.rowIdentifier}
          filtersToQueryParams={this.filtersToQueryParams}
          defaultOrderBy="code"
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          sorts={this.sorts}
          rowDisabled={this.rowDisabled}
          rowLocked={this.rowLocked}
          onDoubleClick={(i) => !i.clientMutationId && onDoubleClick(i)}
          reset={this.state.reset}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rights:
    !!state.core && !!state.core.user && !!state.core.user.i_user
      ? state.core.user.i_user.rights
      : [],
  schemes: state.programs.schemes,
  schemesPageInfo: state.programs.schemesPageInfo,
  fetchingSchemes: state.programs.fetchingSchemes,
  fetchedSchemes: state.programs.fetchedSchemes,
  errorSchemes: state.programs.errorSchemes,
  submittingMutation: state.programs.submittingMutation,
  mutation: state.programs.mutation,
  confirmed: state.core.confirmed,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchSchemeSummaries,
      deleteScheme,
      journalize,
      coreConfirm,
      fetchScheme,
    },
    dispatch
  );
};

export default withModulesManager(
  withHistory(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(injectIntl(withTheme(withStyles(styles)(SchemeSearcher))))
  )
);
