import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import { bindActionCreators } from "redux";
import { formatMessageWithValues, withModulesManager, withHistory } from "@openimis/fe-core";
import { ProgressOrError, Table } from "@openimis/fe-core";
import { fetchPayPoint } from "../actions";
import { Fab } from "@material-ui/core";
import { RIGHT_SERVICE_PROVIDER_ADD } from "../constants";




const styles = theme => ({
    page: theme.page,
    fab: theme.fab

});

class PayPointsList extends Component {

    state = {
        edited: null,
        page: 0,
        pageSize: 5,
        afterCursor: null,
        beforeCursor: null,
    }

    constructor(props) {
        super(props);
        this.rowsPerPageOptions = props.modulesManager.getConf("fe-serviceProvider", "PayPointsList.rowsPerPageOptions", [5, 10, 15, 25, 50, 100]);
        this.defaultPageSize = props.modulesManager.getConf("fe-serviceProvider", "PayPointsList.defaultPageSize", 5);

    }

      //Define some parameters and pass it while fetching payroll from actions
   query = () =>{
    let prams = [];
    prams.push(`first: ${this.state.pageSize}`)
    if(!!this.state.afterCursor){
     prams.push(`after: "${this.state.afterCursor}"`)
    }
    if(!!this.state.beforeCursor){
     prams.push(`before: "${this.state.beforeCursor}"`)
    }
     prams.push(`orderBy: ["serviceprovider"]`);
    this.props.fetchPayPoint(prams,this.props.edited_id);
    }

    onChangeRowsPerPage = (cnt) =>{
        this.setState(
            {pageSize: cnt,
            page: 0,
            afterCursor: null,
            beforeCursor: null,
            },
            e => this.query()
        )
    }

    componentDidMount() {
        this.setState({ orderBy: null }, e => this.onChangeRowsPerPage(this.defaultPageSize))
    }

    serviceproviderChanged = (prevProps) => (!prevProps.serviceprovider && !!this.props.serviceprovider) ||
        (
            !!prevProps.serviceprovider &&
            !!this.props.serviceprovider &&
            (prevProps.serviceprovider.uuid == null || prevProps.serviceprovider.uuid !== this.props.serviceprovider.uuid)
        )

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.serviceproviderChanged(prevProps)) {
            this.query();
            this.setState({ reload: false })
        }
    }

    queryPrms = () => {
        let prms = [];
        if (!!this.state.orderBy) {
            prms.push(`orderBy: "${this.state.orderBy}"`)
        }
        if (!!this.props.serviceprovider && !!this.props.serviceprovider.uuid) {
            prms.push(`uuid:"${this.props.serviceprovider.uuid}"`);
            return prms;
        }
        return null;
    }

    // //this method is binding to payPointList class and it is executed every time this page is called
    // componentDidMount() {
    //     const {
    //         edited_id
    //     } = this.props
    //     //calls the query method to fetch payPoint
    //     this.query(edited_id);

    // }

    // //Define some parameters and pass it while fetching payPoint from actpaypointNameions
    // query = (uuid) => {
        
    //     // let uuid = this.props.edited.uuid;
    //     // prms.push(`first: ${this.state.pageSize} `);
    //     // if (!!this.state.afterCursor) {
    //     //     prms.push(`after: "${this.afterCursor}" `)

    //     // }
    //     // if (!!this.state.beforeCursor) {
    //     //     prms.push(`before: "${this.beforeCursor}" `)

    //     // }
    //     // prms.push( ` orderBy: ["id"]`);

    //     this.props.fetchPayPoint( uuid );
    // }

    onRowDoubleClick = (row) => {
        console.log(row);
    }

    onDoubleClick = (p, newTab = false) => {
        historyPush(this.props.modulesManager, this.props.history, "payPoint.route.PayPointOverview", [p.uuid], newTab)
    }

    onAdd = () => {
        historyPush(this.props.modulesManager, this.props.history, "payPoint.route.paypoint");
    }

    //This function is trigged when the row per page droupdown change which sets the pageSize and recall the query
 

    //This function is trigged on next and previous page change
    onChangePage = (page, nbr) => {
       
        if (nbr > this.state.page) {
            this.setState((state, props) => ({
                page: this.state.page + 1,
                beforeCursor: null,
                afterCursor: this.props.PayPointsListInfo.endCursor,
            }),
                e => this.query(uuid)
            )

        } else if (nbr < this.state.page) {
            this.setState((state, props) => ({
                page: this.state.page - 1,
                beforeCursor: this.props.PayPointsListInfo.startCursor,
                afterCursor: null,
            }),
                e => this.query(uuid)
            )

        }
    }

    render() {
        const { intl, classes, edited_id, fetchingPayPoints, errorPayPoints, PayPoints, PayPointsListInfo, rights } = this.props;

        let headers = [

            "payPoint.paypointName",
            "payPoint.paypointCode",
            "payPoint.serviceProvider",
            "payPoint.geolocation",
            "payPoint.location",

        ]


        let itemFormatters = [

            e => e.paypointName,
            e => e.paypointName,
            e => e.serviceProvider.name,
            e => e.geolocation,
            e => e.location.name,

        ]


        return (
            <div className={classes.page} >

                < ProgressOrError progress={fetchingPayPoints} error={errorPayPoints} />

                <Table
                    module="serviceProvider"
                    fetch={this.props.fetchPayPoint}
                    header={formatMessageWithValues(intl, "serviceProvider", "pay_point.table", { count: PayPointsListInfo.totalCount })}
                    headers={headers}
                    itemFormatters={itemFormatters}
                    items={PayPoints}
                    withPagination={true}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    count={PayPointsListInfo.totalCount}
                    onChangePage={this.onChangePage}
                    onChangeRowsPerPage={this.onChangeRowsPerPage}
                    rowsPerPageOptions={this.rowsPerPageOptions}
                    defaultPageSize={this.defaultPageSize}
                    // onDoubleClick={e => this.onDoubleClick(e)}
                    rights={this.rights}
                />

            </div>
        )
    }
}


const mapStateToProps = state => ({
    // rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    fetchingPayPoints: state.serviceProvider.fetchingPayPoints,
    errorPayPoints: state.serviceProvider.errorPayPoints,
    fetchedPayPoints: state.serviceProvider.fetchedPayPoints,
    PayPoints: state.serviceProvider.PayPoints,
    PayPointsListInfo: state.serviceProvider.PayPointsListInfo,
});

const mapDispatchToProps = dispatch => {
    
    return bindActionCreators({ fetchPayPoint, }, dispatch);
};


export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(PayPointsList))
    ))));