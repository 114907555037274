import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import ReplayIcon from "@material-ui/icons/Replay";
import {
  formatMessageWithValues,
  withModulesManager,
  withHistory,
  historyPush,
  journalize,
  Form,
  ProgressOrError,
} from "@openimis/fe-core";
import { bindActionCreators } from "redux";
import { RIGHT_SCHEME } from "../constants";
// import ProgramsDisplayPanel from "./ProgramsDisplayPanel";
import SchemeMasterPanelEdit from "./SchemeMasterPanelEdit";
import SchemeMasterPanel from "./SchemeMasterPanel";
import ProgramsList from "../pages/ProgramsList";
import { fetchSchemeFull, fetchPrograms, fetchScheme } from "../actions";
import { SchemeLabel } from "../utils";
import AddProgramsPage from "../pages/AddProgramsPage";
import AddSchemePage from "../pages/AddSchemePage";
import BulkInsureeAddition from "./BulkInsureeAddition";

const styles = (theme) => ({
  page: theme.page,
});

const PROGRAMS_SCHEME_FORM_CONTRIBUTION_KEY = "programs.SchemeForm";

class SchemeForm extends Component {
  state = {
    lockNew: false,
    reset: 0,
    scheme_uuid: null,
    scheme: this._newScheme(),
    newScheme: true,
  };

  _newScheme() {
    let scheme = {};
    // scheme.jsonExt = {};
    return scheme;
  }

  //This function checks if a uuid is received....
  componentDidMount() {
    if (this.props.scheme_uuid) {
      this.setState((state, props) => ({ scheme_uuid: props.scheme_uuid }));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.scheme.code !== this.state.scheme.code) {
      document.title = formatMessageWithValues(
        this.props.intl,
        "programs",
        "Scheme.title.bar",
        { label: SchemeLabel(this.state.scheme) }
      );
    }
    if (
      prevProps.fetchedScheme !== this.props.fetchedScheme &&
      !!this.props.fetchedScheme &&
      !!this.props.scheme
    ) {
      this.setState((state, props) => ({
        scheme: { ...props.scheme },
        scheme_uuid: props.scheme.uuid,
        lockNew: false,
        newScheme: false,
      }));
    } else if (prevState.scheme_uuid !== this.state.scheme_uuid) {
      this.props.fetchScheme(
        this.props.modulesManager,
        this.state.scheme_uuid,
        null
      );
    } else if (prevProps.scheme_uuid && !this.props.scheme_uuid) {
      this.setState({
        scheme: this._newScheme(),
        lockNew: false,
        scheme_uuid: null,
      });
    } else if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
      this.setState((state) => ({ reset: state.reset + 1 }));
    }
  }

  _add = () => {
    this.setState(
      (state) => ({
        scheme: this._newScheme(),
        newScheme: true,
        lockNew: false,
        reset: state.reset + 1,
      }),
      (e) => {
        this.props.add();
        this.forceUpdate();
      }
    );
  };

  reload = () => {
    this.props.fetchScheme(
      this.props.modulesManager,
      this.state.scheme_uuid,
      this.state.scheme.code
    );
  };

  canSave = () => {
    if (!this.state.scheme.code) return false;
    if (!this.state.scheme.name) return false;
    if (!this.state.scheme.schemeType) return false;
    if (!this.state.scheme.amount) return false;
    if (!this.state.scheme.status) return false;
    if (!this.state.scheme.startDate) return false;
    if (!this.state.scheme.endDate) return false;
    return true;
  };

  _save = (scheme) => {
    this.setState(
      { lockNew: !scheme.uuid }, // avoid duplicates
      (e) => this.props.save(scheme)
    );
  };

  onEditedChanged = (scheme) => {
    this.setState({ scheme, newScheme: false });
  };

  render() {
    const { fetchingScheme, fetchedScheme, errorScheme, add, save, back } =
      this.props;

    const { lockNew, newScheme, reset, update, overview, scheme_uuid, scheme } =
      this.state;

    let readOnly = lockNew || !!scheme.validityTo;
    let actions = [];

    if (scheme_uuid) {
      actions.push({
        doIt: (e) => this.reload(scheme_uuid),
        icon: <ReplayIcon />,
        onlyIfDirty: !readOnly,
      });
    }

    console.log("Scheme", scheme);

    return (
      <Fragment>
        <ProgressOrError progress={fetchingScheme} error={errorScheme} />
        {(!!fetchedScheme || !scheme_uuid) && (
          <Fragment>
            <Form
              module="programs"
              edited_id={scheme_uuid}
              edited={scheme}
              reset={reset}
              update={update}
              title="Scheme.title.bar"
              titleParams={{ label: SchemeLabel(this.state.scheme) }}
              back={back}
              // add={!!add && !newScheme ? this._add : null}
              save={!!save ? this._save : null}
              canSave={this.canSave}
              reload={(scheme_uuid || readOnly) && this.reload}
              readOnly={readOnly}
              // HeadPanel={ServiceProviderMasterPanel}
              overview={overview}
              Panels={
                scheme_uuid ? [ProgramsList, AddProgramsPage] : [AddSchemePage]
              }
              onEditedChanged={this.onEditedChanged}
              actions={actions}
              contributedPanelsKey={PROGRAMS_SCHEME_FORM_CONTRIBUTION_KEY}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  rights:
    !!state.core && !!state.core.user && !!state.core.user.i_user
      ? state.core.user.i_user.rights
      : [],
  fetchingScheme: state.programs.fetchingScheme,
  errorScheme: state.programs.errorScheme,
  fetchedScheme: state.programs.fetchedScheme,
  scheme: state.programs.scheme,
  // fetchingPrograms: state.programs.fetchingPrograms,
  // errorPrograms: state.programs.errorPrograms,
  // fetchedPrograms: state.programs.fetchedPrograms,
  // programs: state.programs.programs,
  submittingMutation: state.programs.submittingMutation,
  mutation: state.programs.mutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchScheme, journalize }, dispatch);
};

// export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
//     injectIntl(withTheme(withStyles(styles)(SchemeForm))
//     ))));

export default withModulesManager(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(SchemeForm))
);
