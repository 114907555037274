import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import ReplayIcon from "@material-ui/icons/Replay"
import {
    formatMessageWithValues, withModulesManager, withHistory, historyPush,
    Form, ProgressOrError, journalize, coreConfirm, parseData
} from "@openimis/fe-core";
import { RIGHT_PAYROLLCYCLE, RIGHT_PAYROLLCYCLE_EDIT } from "../../constants";
import PayrollcycleMasterPanel from "../Panels/PayrollcycleMasterPanel";

import { fetchPayrollcycle, newPayrollcycle, createPayrollcycle, fetchPayrollcycleMutation, generatePayroll, bulkImportPayroll } from "../../actions";
import PayrollcyclePayrollOverview from "../Panels/PayrollcyclePayrollOverview";
import PayrollcycleEditPanel from "../Panels/PayrollcycleEditPanel";

import { PayrollcycleLabel } from "../../utils";

const styles = theme => ({
    lockedPage: theme.page.locked
});

const PAYROLL_PAYROLLCYCLE_PANELS_CONTRIBUTION_KEY = "payroll.payrollcycle.panels"
const PAYROLL_PAYROLLCYCLE_OVERVIEW_PANELS_CONTRIBUTION_KEY = "payroll.PayrollcycleOverview.panels"
const PAYROLL_PAYROLLCYCLE_OVERVIEW_CONTRIBUTED_MUTATIONS_KEY = "payroll.PayrollcycleOverview.mutations"

class PayrollcycleForm extends Component {

    state = {
        lockNew: false,
        reset: 0,
        payrollcycle: this._newPayrollcycle(),
        payrollData:{
            locationCode:"VM0001",
            pspUuid: "300c0574-ef99-4333-aa7b-f2d7686c103e",
        },
        newPayrollcycle: true,
        consirmedAction: null,
    }

    _newPayrollcycle() {
        let payrollcycle = {};
        payrollcycle.jsonExt = {};
        return payrollcycle;
    }

    componentDidMount() {
        document.title = formatMessageWithValues(this.props.intl, "payroll", !!this.props.overview ? "PayrollcycleOverview.title" : "payrollcycle.title", { label: "" })
        if (this.props.payrollcycle_uuid) {
            this.setState(
                (state, props) => ({ payrollcycle_uuid: props.payrollcycle_uuid }),
                e => this.props.fetchPayrollcycle(
                    this.props.modulesManager,
                    this.props.payrollcycle_uuid
                )
            )
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.payrollcycle && prevState.payrollcycle.name)
            !== (this.state.payrollcycle && this.state.payrollcycle.name)) {
            document.title = formatMessageWithValues(this.props.intl, "payroll", !!this.props.overview ? "PayrollcycleOverview.title" : "payrollcycle.title", { label: PayrollcycleLabel(this.state.payrollcycle) })
        }
        if (!prevProps.fetchedPayrollcycle && !!this.props.fetchedPayrollcycle) {
            var payrollcycle = this.props.payrollcycle;
            if (payrollcycle) {
                payrollcycle.ext = !!payrollcycle.jsonExt ? JSON.parse(payrollcycle.jsonExt) : {};
                this.setState(
                    { payrollcycle, payrollcycle_uuid: payrollcycle.uuid, lockNew: false, newPayrollcycle: false });
            }
        } else if (prevProps.payrollcycle_uuid && !this.props.payrollcycle_uuid) {
            document.title = formatMessageWithValues(this.props.intl, "payroll", !!this.props.overview ? "PayrollcycleOverview.title" : "payrollcycle.title", { label: PayrollcycleLabel(this.state.payrollcycle) })
            this.setState({ payrollcycle: this._newPayrollcycle(), newPayrollcycle: true, lockNew: false, payrollcycle_uuid: null });
        } else if (prevProps.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);

            this.setState((state, props) => ({
                payrollcycle: { ...state.payrollcycle, clientMutationId: this.props.mutation.clientMutationId }
            }));
        } else if (prevProps.confirmed !== this.props.confirmed && !!this.props.confirmed && !!this.state.confirmedAction) {
            this.state.confirmedAction();
        }
    }

    _add = () => {
        this.setState((state) => ({
            payrollcycle: this._newPayrollcycle(),
            newPayrollcycle: true,
            lockNew: false,
            reset: state.reset + 1,
        }),
            e => {
                this.props.add();
                this.forceUpdate();
            }
        )
    }

    _canGeneratePayroll= (pageInfo,fetching) =>{
        if(!fetching && pageInfo && pageInfo.totalCount <= 0){
            return true
        }else{
            return false
        }
    }

    reload = () => {
        const { payrollcycle} = this.state;
        const { clientMutationId, payrollcycleUuid } = this.props.mutation;
        if (clientMutationId && !payrollcycleUuid ) { // creation, we need to fetch the new payrollcycle uuid from mutations logs and redirect to payrollcycle overview
            this.props.fetchPayrollcycleMutation(
                this.props.modulesManager,
                clientMutationId
            ).then(res => {
                const mutationLogs = parseData(res.payload.data.mutationLogs)
                if (mutationLogs
                    && mutationLogs[0]
                    && mutationLogs[0].payrollcycles
                    && mutationLogs[0].payrollcycles[0]
                    && mutationLogs[0].payrollcycles[0].payrollcycle) {
                        const uuid = parseData(res.payload.data.mutationLogs)[0].payrollcycles[0].payrollcycle.uuid;
                        if (uuid) {
                            historyPush(this.props.modulesManager, this.props.history, "payroll.route.PayrollcycleOverview", [uuid]);
                        }
                }
            });
        } else {
                this.props.fetchPayrollcycle(
                this.props.modulesManager,
                payrollcycleUuid,
                payrollcycle.clientMutationId
            );
        }
    }
    canSave = () => {
        if (!this.state.payrollcycle.scheme) return false;
        if (!this.state.payrollcycle.payrollCycleName) return false;
        if (!this.state.payrollcycle.startDate) return false;
        if (!this.state.payrollcycle.endDate) return false;
        return true;
    }

    _save = (payrollcycle) => {
        this.setState(
            { lockNew: !payrollcycle.uuid }, // avoid duplicates
            e => this.props.save(payrollcycle))
    }

    onEditedChanged = payrollcycle => {
        this.setState({ payrollcycle, newPayrollcycle: false })
    }

    onActionToConfirm = (title, message, confirmedAction) => {
        this.setState(
            { confirmedAction },
            this.props.coreConfirm(
                title,
                message
            )
        )
    }

    // bulkImportPayroll = e => {
	// 	this.props.bulkImportPayroll(this.state.edited, `Payroll Imported Successfully`)
    // }

    onClickGeneratePayroll = () => {
        let confirmedAction = () => {
            this.props.generatePayroll(
                this.props.modulesManager,
                this.props.payrollcycle.uuid,
                this.state.payrollData,
                formatMessageWithValues(this.props.intl, "payroll", "GeneratePayroll.mutationLabel", { label: PayrollcycleLabel(this.state.payrollcycle) }),
            );
        }
        this.onActionToConfirm(
            formatMessageWithValues(this.props.intl, "payroll", "generatePayrollDialog.title", { label: PayrollcycleLabel(this.state.payrollcycle) }),
            formatMessageWithValues(this.props.intl, "payroll", "generatePayrollDialog.message",
                {
                    label: PayrollcycleLabel(this.state.payrollcycle),
                }),
            confirmedAction)
    }

    render() {
        const { modulesManager, canGeneratepayroll, classes, state, rights,
            payrollcycle_uuid, fetchingPayrollcycle, fetchedPayrollcycle, errorPayrollcycle, payroll,
            overview = false, openPayrollcycleButton, readOnly = false,
            add, save, back, mutation,pageInfo, fetchingPayrolls } = this.props;
        const { payrollcycle, newPayrollcycle } = this.state;
        if (!rights.includes(RIGHT_PAYROLLCYCLE)) return null;
        let runningMutation = !!payrollcycle && !!payrollcycle.clientMutationId
        let contributedMutations = modulesManager.getContribs(PAYROLL_PAYROLLCYCLE_OVERVIEW_CONTRIBUTED_MUTATIONS_KEY);
        for (let i = 0; i < contributedMutations.length && !runningMutation; i++) {
            runningMutation = contributedMutations[i](state)
        }
        let actions = [];
        if (payrollcycle_uuid || !!payrollcycle.clientMutationId) {
            actions.push({
                doIt: this.reload,
                icon: <ReplayIcon />,
                onlyIfDirty: !readOnly && !runningMutation
            });
        }
        return (
            <div className={!!runningMutation ? classes.lockedPage : null}>
                <ProgressOrError progress={fetchingPayrollcycle} error={errorPayrollcycle} />
                {((!!fetchedPayrollcycle && !!payrollcycle && payrollcycle.uuid === payrollcycle_uuid) || !payrollcycle_uuid) && (
                    <Form
                        module="payroll"
                        title="PayrollcycleOverview.title"
                        titleParams={{ label: PayrollcycleLabel(this.state.payrollcycle) }}
                        edited_id={payrollcycle_uuid}
                        edited={payrollcycle}
                        reset={this.state.reset}
                        back={back}
                        add={!!add && !newPayrollcycle ? this._add : null}
                        readOnly={readOnly || runningMutation || !!payrollcycle.validityTo}
                        actions={actions}
                        openPayrollcycleButton={openPayrollcycleButton}
                        overview={overview}
                        HeadPanel={PayrollcycleMasterPanel}
                        Panels={overview ? [PayrollcyclePayrollOverview] : [PayrollcycleEditPanel]}
                        contributedPanelsKey={overview ? PAYROLL_PAYROLLCYCLE_OVERVIEW_PANELS_CONTRIBUTION_KEY : PAYROLL_PAYROLLCYCLE_PANELS_CONTRIBUTION_KEY}
                        payrollcycle={payrollcycle}
                        payroll={payroll}
                        onEditedChanged={this.onEditedChanged}
                        canSave={this.canSave}
                        save={!!save ? this._save : null}
                        onActionToConfirm={this.onActionToConfirm}
                        canGeneratepayroll={this._canGeneratePayroll(pageInfo,fetchingPayrolls)}
                        onClickGeneratePayroll={this.onClickGeneratePayroll}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    fetchingPayrollcycle: state.payroll.fetchingPayrollcycle,
    errorPayrollcycle: state.payroll.errorPayrollcycle,
    fetchedPayrollcycle: state.payroll.fetchedPayrollcycle,
    payrollcycle: state.payroll.payrollcycle,
    submittingMutation: state.payroll.submittingMutation,
    mutation: state.payroll.mutation,
    payroll: state.payroll.payroll,
    confirmed: state.core.confirmed,
    state: state,
    pageInfo: state.payroll.cyclePayrollsPageInfo,
    fetchingPayrolls: state.payroll.fetchingCyclePayrolls,
})

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ fetchPayrollcycleMutation, fetchPayrollcycle, newPayrollcycle, createPayrollcycle, generatePayroll, bulkImportPayroll, journalize, coreConfirm }, dispatch);
};

export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(PayrollcycleForm))
    ))));