export const locales = ["en-GB", "fr-FR", "en-al", "en-ct", "en"];
export const fileNamesByLang = {
  "en": "en-ct",
  "en-GB": "en-ct",
  "en-al": "en-al",
  "fr": "fr",
  "en-ct": "en-ct",
  "fr-FR": "fr",
}; /* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { "en": "en-ct", "en-GB": "en-ct", "en-al": "en-al", "fr": "fr-FR", "fr-FR": "fr-FR" };
