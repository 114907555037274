import {
    formatServerError, formatGraphQLError, parseData, pageInfo, dispatchMutationReq,
    dispatchMutationErr, dispatchMutationResp
} from '@openimis/fe-core';

function reducer(
    //This state contain all veriables to peform in a query
    //then return an outcome from the query....
    state = {
        fetchingServiceProviders: false,
        errorServiceProviders: null,
        fetchedServiceProviders: false,
        ServiceProviders: null,
        ServiceProvidersListInfo: { totalCount: 0 },

        fetchingServiceProvider: false,
        fetchedServiceProvider: false,
        errorServiceProvider: null,
        errorServiceProvider: null,
        ServiceProvider: null,


        checkCanAddPaypoint: false,
        checkingCanAddPaypoint: false,
        checkedCanAddPaypoint: false,
        canAddPaypointWarnings: [],
        errorCanAddPaypoint: null,

        fetchingPayPoints: false,
        errorPayPoints: null,
        fetchedPayPoints: false,
        PayPoints: null,
        PayPoint: null,
        PayPointsListInfo: { totalCount: 0 },

        submittingMutation: false,
        mutation: {},

    },
    action,
) {

    //This statement perform all iteration on any request send
    //thhat has a corresponding CASE condition...
    switch (action.type) {
        case 'SERVICE_PROVIDER_SERVICE_PROVIDERS_LIST_REQ':

            return {
                ...state,
                fetchingServiceProviders: true,
                fetchedServiceProviders: false,
                ServiceProviders: [],
                ServiceProvidersListInfo: { totalCount: 0 },
                errorServiceProviders: null,

            };

        case 'SERVICE_PROVIDER_SERVICE_PROVIDERS_LIST_RESP':

            return {
                ...state,
                fetchingServiceProviders: false,
                fetchedServiceProviders: true,
                ServiceProviders: parseData(action.payload.data.serviceProvider),
                ServiceProvidersListInfo: pageInfo(action.payload.data.serviceProvider),
                errorServiceProviders: formatGraphQLError(action.payload),
            };


        case 'SERVICE_PROVIDER_SERVICE_PROVIDERS_LIST_ERR':

            return {
                ...state,
                fetchingServiceProviders: false,
                errorServiceProviders: formatServerError(action.payload),
            };

        case 'SERVICEPROVIDER_SERVICEPROVIDERS_REQ':
            return {
                ...state,
                fetchingServiceProviders: true,
                fetchedServiceProviders: false,
                ServiceProviders: [],
                errorServiceProviders: null,
            };
        case 'SERVICEPROVIDER_SERVICEPROVIDERS_RESP':
            return {
                ...state,
                fetchingServiceProviders: false,
                fetchedServiceProviders: true,
                ServiceProviders: parseData(action.payload.data.serviceProvider),
                ServiceProvidersListInfo: pageInfo(action.payload.data.serviceProvider),
                errorServiceProviders: formatGraphQLError(action.payload),
            };
        case 'SERVICEPROVIDER_SERVICEPROVIDERS_ERR':
            return {
                ...state,
                fetchingServiceProviders: false,
                errorServiceProviders: formatServerError(action.payload),
            };

        case 'SERVICE_PROVIDER_SERVICE_PROVIDER_REQ':

            return {
                ...state,
                fetchingServiceProvider: true,
                fetchedServiceProvider: false,
                ServiceProvider: null,
                errorServiceProvider: null,

            };

        case 'SERVICE_PROVIDER_SERVICE_PROVIDER_RESP':
            var SPs = parseData(action.payload.data.serviceProviderDetails);
            return {
                
                ...state,
                fetchingServiceProvider: false,
                fetchedServiceProvider: true,
                ServiceProvider: (!!SPs && SPs.length > 0) ? SPs[0] : null,
                errorServiceProvider: formatGraphQLError(action.payload)
            };

        case 'SERVICE_PROVIDER_STR_REQ':
            return {
                ...state,
                fetchingServiceProvider: true,
                fetchedServiceProvider: false,
                ServiceProvider: null,
                errorServiceProvider: null,
            };
        case 'SERVICE_PROVIDER_STR_RESP':
            return {
                ...state,
                fetchingServiceProvider: false,
                fetchedServiceProvider: true,
                serviceProvider: parseData(action.payload.data.ServiceProviderStr),
                errorServiceProvider: formatGraphQLError(action.payload)
            };

        case 'SERVICE_PROVIDER_STR_ERR':
            return {
                ...state,
                fetchingServiceProvider: false,
                errorServiceProvider: formatServerError(action.payload)
            };

        case 'SERVICEPROVIDER_PAYPOINT_MEMBERS_REQ':
            return {
                ...state,
                fetchingPayPoints: true,
                fetchedPayPoints: false,
                errorPayPoints: null,
            };
        case 'SERVICEPROVIDER_PAYPOINT_MEMBERS_RESP':
            return {
                ...state,
                fetchingPayPoints: false,
                fetchedPayPoints: true,
                PayPoints: parseData(action.payload.data.paypoint),
                PayPointsListInfo: pageInfo(action.payload.data.paypoint),
                errorPayPoints: formatGraphQLError(action.payload)
            };
        case 'SERVICEPROVIDER_PAYPOINT_MEMBERS_ERR':
            return {
                ...state,
                fetchingPayPoints: false,
                errorPayPoints: formatServerError(action.payload)
            };
        case 'SERVICEPROVIDER_PAYPOINT_MEMBER':
            return {
                ...state,
                insuree: action.payload,
            };

        case 'LOCATION_USER_HEALTH_FACILITY_FULL_PATH_RESP':
            var userHealthFacilityFullPath = parseData(action.payload.data.ServiceProviders)[0]
            return {
                ...state,
                userHealthFacilityFullPath,
                userHealthFacilityLocationStr: !!userHealthFacilityFullPath.name ? ServiceProviderLabel(userHealthFacilityFullPath.name) : null,
            }

        case 'SERVICEPROVIDER_PAYPOINT_MEMBER':
            return {
                ...state,
                PayPoint: action.payload,
            };

        case 'SERVICE_PROVIDER_NEW':
            return {
                ...state,
                ServiceProviderListInfo: { totalCount: 0 },
                ServiceProvider: null,
                // ServiceProvider: null,
            };

        case 'SERVICEPROVIDER_PAYPOINTS_LIST_REQ':

            return {
                ...state,
                fetchingPayPoints: true,
                fetchedPayPoints: false,
                PayPoints: null,
                PayPointsListInfo: { totalCount: 0},
                errorPayPoints: null,

            };

        case 'SERVICEPROVIDER_PAYPOINTS_LIST_RESP':

            return {
                ...state,
                fetchingPayPoints: false,
                fetchedPayPoints: true,
                PayPoints: parseData(action.payload.data.serviceProviderPaypointDetails),
                PayPointsListInfo: pageInfo(action.payload.data.serviceProviderPaypointDetails),
                errorPayPoints: formatGraphQLError(action.payload),
            };


        case 'SERVICEPROVIDER_PAYPOINTS_LIST_ERR':

            return {
                ...state,
                fetchingPayPoints: false,
                errorPayPoints: formatServerError(action.payload),
            };


        case 'SERVICE_PROVIDER_MUTATION_REQ':
            return dispatchMutationReq(state, action);

        case 'SERVICE_PROVIDER_MUTATION_ERR':
            return dispatchMutationErr(state, action);

        case 'SERVICE_PROVIDER_CREATE_SERVICE_PROVIDER_RESP':
            return dispatchMutationResp(state, "createServiceProvider", action);

        case 'SERVICE_PROVIDER_UPDATE_SERVICE_PROVIDER_RESP':
            return dispatchMutationResp(state, "updateServiceProvider", action);

        case 'SERVICE_PROVIDER_DELETE_SERVICE_PROVIDER_RESP':
            return dispatchMutationResp(state, "deleteServiceProvider", action);

        default:
            return state;
    }

}

export default reducer;