import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from 'react-intl';
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import {
    withModulesManager, decodeId, formatDateFromISO,
    journalize, coreConfirm,
    Searcher,
} from "@openimis/fe-core";
import {  fetchServiceProviderPayPoints, fetchPaypointSummaries, fetchPayPoint } from "../actions";
import {
    formatMessage, formatMessageWithValues,
} from "@openimis/fe-core";

import { IconButton } from "@material-ui/core";

import {RIGHT_PAY_POINT_DELETE } from "../constants";

const SERVICEPROVIDER_SEARCHER_CONTRIBUTION_KEY = "serviceProvider.PaypointsSearcher";

class PaypointsSearcher extends Component {

    constructor(props) {

        super(props);
        this.rowsPerPageOptions = props.modulesManager.getConf("fe-serviceProvider", "ServicePFilter.rowsPerPageOptions", [5, 10, 20, 50, 100]);
        this.defaultPageSize = props.modulesManager.getConf("fe-serviceProvider", "ServicePFilter.defaultPageSize", 10);
    }

    //    componentDidMount() {
    //     if (this.props.edited) {
    //         this.setState(
    //             (state, props) => ({ edited: props.edited }),
    //             e => this.props.fetchPayPoint(
    //                 this.props.modulesManager,
    //                 this.props.edited.id
    //             ),
    //         )
    //     }
    // }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        } else if (prevProps.confirmed !== this.props.confirmed && !!this.props.confirmed && !!this.state.confirmedAction) {
            this.state.confirmedAction();
        }
    }

    rowIdentifier = (r) => r.uuid

    headers = (filters) => {
        let headers = [
            "payPoint.paypointName",
            "payPoint.paypointCode", 
            "payPoint.serviceProvider",
            "payPoint.geolocation",
            "payPoint.location",
        ]
        if (this.props.rights.includes(RIGHT_PAY_POINT_DELETE)) {
            
            // headers.push("payPoint.delete");
        }
        return headers;
    }

    // sorts = (filters) => [
    //     ['paypointName', true],
    //     null,
    //     null,
    //     null,
    //     null,
    //     ['validityFrom', false],
    //     ['validityTo', false]
    // ]

    itemFormatters = (filters) => {
        let formatters = [
            pp => pp.paypointName,
            pp => pp.paypointCode,
            pp => pp.serviceProvider.name,
            pp => pp.geolocation,
            pp => pp.location.name,
            // pp => formatDateFromISO(
            //     this.props.modulesManager,
            //     this.props.intl,
            //     pp.validityFrom),
            // pp => formatDateFromISO(
            //     this.props.modulesManager,
            //     this.props.intl,
            //     pp.validityTo),
        ]
        // if (this.props.rights.includes(RIGHT_PAY_POINT_DELETE)) {
        //     formatters.push(pp => !!pp.validityTo ? null : <IconButton
        //         onClick={e => this.onDelete(pp)}>
        //         <DeleteIcon />
        //     </IconButton>);
        // }
        return formatters;
    }

    rowDisabled = (selection, i) => !!i.validityTo

    onDelete = pp => {
        let confirm = e => this.props.coreConfirm(
            formatMessage(this.props.intl, "serviceProvider", "deleteServiceProvider.title"),
            formatMessageWithValues(this.props.intl, "serviceProvider", "deleteServiceProviderDialog.message",

                { uuid: pp.uuid,}),
        );
        let confirmedAction = () => this.props.deleteServiceProvider(
            pp,
            formatMessageWithValues(
                this.props.intl,
                "serviceProvider",
                "DeleteServiceProvider.mutationLabel",
                { uuid: pp.uuid }
            )
        );
        this.setState(
            { confirmedAction },
            confirm
        )
    }

    rowLocked = (selection, pp) => !!pp.clientMutationId

    render() {
        const {
            intl,
            PayPoints, PayPointsListInfo, ServiceProvider,
            fetchingPayPoints, fetchedPayPoints, errorPayPoints,edited
        } = this.props;
        let count = PayPointsListInfo.totalCount;
        return (
            <Fragment>
                <Searcher
                    module="serviceProvider"
                    fetch={this.props.fetchPaypointSummaries}
                    // cacheFiltersKey="paypointSearcher"
                    items={ServiceProvider && PayPoints}
                    rowIdentifier={this.rowIdentifier}
                    rowLocked={this.rowLocked}
                    itemsPageInfo={PayPointsListInfo}
                    fetchingItems={fetchingPayPoints}
                    fetchedItems={fetchedPayPoints}
                    errorItems={errorPayPoints}
                    tableTitle={formatMessageWithValues(intl, "serviceProvider", "Paypoints Summary", { count })}
                    rowsPerPageOptions={this.rowsPerPageOptions}
                    defaultPageSize={this.defaultPageSize}
                    headers={this.headers}
                    itemFormatters={this.itemFormatters}
                    rowDisabled={this.rowDisabled}
                    // sorts={this.sorts}
                    // onDoubleClick={onDoubleClick}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
    submittingMutation: state.serviceProvider.submittingMutation,
    mutation: state.serviceProvider.mutation,
    confirmed: state.core.confirmed,
    ServiceProvider: state.serviceProvider.ServiceProvider,
    PayPoints: state.serviceProvider.PayPoints,
    PayPointsListInfo: state.serviceProvider.PayPointsListInfo,
    fetchingPayPoints: state.serviceProvider.fetchingPayPoints,
    fetchedPayPoints: state.serviceProvider.fetchedPayPoints,
    errorPayPoints: state.serviceProvider.errorPayPoints,

});

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {fetchPaypointSummaries, coreConfirm, journalize },
        dispatch);
};

export default withModulesManager(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaypointsSearcher)));