import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";

import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  Fab,
} from "@material-ui/core";

import { CloudUpload as UploadIcon } from "@material-ui/icons";

import PayrollCycleUploadPage from "../PayrollCycleUpload";

import {
  People as PeopleIcon,
  Launch as GenerateIcon,
  Publish,
} from "@material-ui/icons";
import {
  historyPush,
  withHistory,
  withModulesManager,
  TextInput,
  formatMessage,
  PublishedComponent,
  FormattedMessage,
  FormPanel,
  YearPicker,
  withTooltip,
} from "@openimis/fe-core";

import { Exporter } from "@openimis/fe-exporter";
const styles = (theme) => ({
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
    display: "flex",
  },
  // fab: theme.fab,
  fabContainer: {
    float: "right",
  },
  fab: {
    float: "right",
  },
});

class PayrollcycleMasterPanel extends FormPanel {
  state = {
    file: null,
    status: null,
  };

  fileReader = new FileReader();

  handleOnChange = (e) => {
    this.setState({
      file: e.target.files[0],
    });
  };

  handleOnSubmit = (e) => {
    let content = null;
    e.preventDefault();
    //read the file and send the content as an object graphql mutation
    this.fileReader.readAsText(this.state.file);
    this.fileReader.onload = (e) => {
      content = e.target.result;

      const { modulesManager, intl } = this.props;
      const { formatMessage } = intl;
      let payrollsToImport = [];
      if (content) {
        for (let p in content) {
          let payroll = content[p];
          let pay = {
            scheme: {
              code: payroll.ProgramCode,
              name: payroll.Program,
            },
            insuree: {
              chfId: payroll.SocialRegistryNumber,
              otherNames: payroll.BeneficiaryName,
              phone: payroll.TelephoneNumber,
              accountNumber: payroll.AccountNumber,
            },
            expectedamount: payroll.Amount,
            transactionNumber: payroll.TransactionNumber,
            voucherNumber: payroll.VoucherNumber,
            paymentStatus: payroll.PaymentStatus,
            reasonsForNotPaid: payroll.ReasonsForNotPaid,
            receivedDate: payroll.ReceivedDate,
            payPointCode: payroll.PayPointCode,
          };
          payrollsToImport.push(pay);
        }

        let payload = {
          payrolls: payrollsToImport,
        };
        modulesManager
          .runModuleMethod("fe-payroll", "savePayrolls", [payload])
          .then(() => {
            this.setState({
              status: formatMessage({
                id: "payrollcycle.upload.success",
                defaultMessage: "Payroll uploaded successfully",
              }),
            });
          })
          .catch((err) => {
            this.setState({
              status: formatMessage({
                id: "payrollcycle.upload.failure",
                defaultMessage: "Payroll upload failed",
              }),
            });
          });
      }
    };
  };
  formatSchemeType = (schemeType) => {
    return schemeType.replace("_", "-");
  };
  formateForExport = (payrolls) => {
    let items = [];

    if (payrolls) {
      for (let p in payrolls) {
        let payroll = payrolls[p];
        items.push({
          ProgramCode: payroll.scheme ? payroll.scheme.code : "",
          Program: payroll.scheme ? payroll.scheme.name : "",
          SocialRegistryNumber: payroll.insuree ? payroll.insuree.chfId : "",
          BeneficiaryName: payroll.insuree
            ? payroll.insuree.otherNames + " " + payroll.insuree.lastName
            : "",
          TelephoneNumber: payroll.insuree ? payroll.insuree.phone : "",
          AccountNumber: payroll.insuree ? payroll.insuree.accountNumber : "",
          HHAccountNumber:
            payroll.insuree &&
            payroll.insuree.family &&
            payroll.insuree.family.headInsuree
              ? payroll.insuree.family.headInsuree.accountNumber
              : "",
          Amount: payroll.expectedamount ? payroll.expectedamount : "",
          TransactionNumber: payroll.transactionNumber
            ? payroll.transactionNumber
            : "",
          VoucherNumber: "",
          PaymentStatus: payroll.paymentStatus ? payroll.paymentStatus : "",
          ReasonsForNotPaid: "",
          ReceivedDate: payroll.receivedDate ? payroll.receivedDate : "",
          PayPointCode: "",
        });
      }
    } else {
      items.push({
        ProgramCode: "",
        Program: "",
        SocialRegistryNumber: "",
        BeneficiaryName: "",
        TelephoneNumber: "",
        AccountNumber: "",
        Amount: "",
        TransactionNumber: "",
        VoucherNumber: "",
        PaymentStatus: "",
        ReasonsForNotPaid: "",
        ReceivedDate: "",
        PayPointCode: "",
      });
    }
    return items;
  };
  payrollcycleSummary = () => {
    const { classes, edited, overview } = this.props;
    return (
      <Fragment>
        <PayrollCycleUploadPage />

        <Divider />
        <Grid container className={classes.item}>
          <Grid item xs={6} className={classes.item}>
            <TextInput
              module="payroll"
              label="payrollcycles.payrollCycleNameInput"
              required={true}
              value={edited.payrollCycleName}
              readOnly={true}
              onChange={(v) => this.updateAttribute("payrollCycleName", v)}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <PublishedComponent
              pubRef="scheme.DropDownSchemesPicker"
              value={edited.scheme}
              onChange={(v) => this.updateAttribute("scheme", v)}
              required={true}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <PublishedComponent
              pubRef="core.DatePicker"
              label="schemeForm.startDate"
              value={edited.startDate}
              required={true}
              readOnly={true}
              onChange={(v) => this.updateAttribute("startDate", v)}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <PublishedComponent
              pubRef="core.DatePicker"
              label="schemeForm.endDate"
              value={edited.endDate}
              required={true}
              readOnly={true}
              onChange={(v) => this.updateAttribute("endDate", v)}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  render() {
    const {
      intl,
      canGeneratepayroll = false,
      classes,
      edited,
      openPayrollcycleButton = false,
      readOnly,
      overview,
      onClickGeneratePayroll,
      payrolls,
    } = this.props;
    return (
      <Fragment>
        <Grid container className={classes.tableTitle}>
          <Grid item xs={12}>
            <Grid
              container
              align="center"
              justify="center"
              direction="column"
              className={classes.fullHeight}
            >
              <Grid item xs={2}>
                <Typography>
                  <FormattedMessage
                    module="payroll"
                    id="payroll.PayrollcycleDetailPanel.title"
                  />
                </Typography>
              </Grid>
              <Grid xs={10} item className={classes.fabContainer}>
                {!!overview && (
                  <Exporter
                    data={this.formateForExport(payrolls)}
                    fileName={edited.payrollCycleName}
                    exportType={"csv"}
                  />
                )}

                {!!overview && !!canGeneratepayroll && (
                  <Tooltip
                    title={formatMessage(
                      intl,
                      "payroll",
                      "GeneratePayrollTooltip"
                    )}
                  >
                    <IconButton onClick={onClickGeneratePayroll}>
                      <GenerateIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!!overview && this.payrollcycleSummary()}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  payrolls: state.payroll.cyclePayrolls,
});

export default withModulesManager(
  withHistory(
    injectIntl(
      withTheme(
        withStyles(styles)(
          connect(mapStateToProps, null)(PayrollcycleMasterPanel)
        )
      )
    )
  )
);
