import React, { Component } from "react";
import { injectIntl } from 'react-intl';
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SaveAlt, Save } from "@material-ui/icons";

import {
    Fab,
    Button,
    Paper,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Divider,
    Tooltip,
    IconButton
} from "@material-ui/core";

import {
    PublishedComponent, ControlledField,
    formatMessageWithValues, withModulesManager, withHistory, historyPush, FormattedMessage, TextInput, journalize, withTooltip, formatMessage
} from "@openimis/fe-core";

import { createServiceProvider } from "../actions";


//Setup some stylings to adopt in the page...
const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});


// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

//This class generate the form to Add Service Provider.....
class CreateServiceProvider extends Component {
    state = {
        edited: {},
    }


    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }
    }


    //Create function for onClick button to create service provider...
    saveSPData = e => {
        //This line call the create service provider function and pass in the data
        //to be save into the database....
        this.props.createServiceProvider(this.state.edited, `Creating ${this.state.edited.name}`)

        //This line gets the value of the name input
        let output = `${this.state.edited.name}`;

        //These conditions throws the toast message....
        if (output === "undefined") {

            let message = <strong><em><h2>Fail To Create Service Provider</h2></em></strong>;
            // toast.error(message, {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'toast-notify',
            //     progressClassName: 'notify-progress-bar',
            //     autoClose: 60000
            // });

        } else {


            let message = <strong><em><h2>Created {this.state.edited.name} Successfully...</h2></em></strong>;
            // toast.success(message, {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'toast-notify',
            //     progressClassName: 'notify-progress-bar',
            //     autoClose: 60000
            // });

        }
    }


    updateAttribute = (k, v) => {
        this.setState((state) => ({
            edited: { ...state.edited, [k]: v }

        }),
        )
    }


    render() {
        // const { classes, edited,edited_id, reset, readOnly = false } = this.props;

        const { intl, classes, openServiceProviderButton = false } = this.props;

        const { edited } = this.state;

        //This part returns the form fields to add Service Providers....
        return (
            <div className={classes.page} >
                <Grid container className={classes.tableTitle}>
                    <Grid item>
                        <Grid container align="center" justifyContent="center" direction="column" className={classes.fullHeight}>
                            <Grid item>
                                <Typography >
                                    <FormattedMessage module="serviceProvider" id="serviceProvider.ServiceProviderDetailPanel.title" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {!!openServiceProviderButton && !!overview && !!edited.uuid && (
                        <Grid item>
                            <Tooltip title={formatMessage(this.props.intl, "serviceProvider", "ServiceProviderSummaries.openServiceProviderButton.tooltip")}>
                                <IconButton onClick={e => historyPush(this.props.modulesManager, this.props.history, "serviceProvider.route.serviceProviderlOverview", [edited.uuid])}><PeopleIcon /></IconButton >
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
                <Divider />
                <br />

                <Grid container className={classes.item} >
                    <Paper className={classes.paper}>

                        <Grid container xs={12} className={classes.item}>

                            <ControlledField module="serviceProvider" id="ServiceProvider.name" field={
                                <Grid item xs={2} className={classes.item}>
                                    <TextInput
                                        model="serviceProvider"
                                        label="ServiceProviderForm.name"
                                        value={edited.name}
                                        required={true}
                                        onChange={v => this.updateAttribute("name", v)}

                                    />

                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.code" field={
                                <Grid item xs={2} className={classes.item}>
                                    <TextInput
                                        model="serviceProvider"
                                        label="ServiceProviderForm.code"
                                        value={edited.code}
                                        required={true}
                                        inputProps={{
                                            "maxLength": this.codeMaxLength,
                                        }}
                                        onChange={v => this.updateAttribute("code", v)}

                                    />
                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.legalForm.legalForm" field={
                                <Grid item xs={2} className={classes.item}>
                                    <PublishedComponent
                                        model="serviceProvider"
                                        pubRef="serviceProvider.ServiceProviderLegalFormPicker"
                                        value={edited.legalForm}
                                        required={true}
                                        onChange={v => this.updateAttribute("legalForm", v)}
                                    />

                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.level.level" field={
                                <Grid item xs={2} className={classes.item}>
                                    <PublishedComponent
                                        model="serviceProvider"
                                        pubRef="serviceProvider.ServiceProviderLevelPicker"
                                        value={edited.level}
                                        required={true}
                                        onChange={v => this.updateAttribute("level", v)}

                                    />
                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.subLevel.subLevel" field={
                                <Grid item xs={2} className={classes.item}>
                                    <PublishedComponent
                                        model="serviceProvider"
                                        pubRef="serviceProvider.ServiceProviderSubLevelPicker"
                                        value={edited.subLevel}
                                        required={true}
                                        onChange={v => this.updateAttribute("subLevel", v)}

                                    />
                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.accountCode" field={
                                <Grid item xs={2} className={classes.item}>
                                    <TextInput
                                        model="serviceProvider"
                                        label="ServiceProviderForm.accountCode"
                                        value={edited.accountCode}
                                        required={true}
                                        onChange={v => this.updateAttribute("accountCode", v)}

                                    />
                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.address" field={
                                <Grid item xs={3} className={classes.item}>
                                    <TextInput
                                        model="serviceProvider"
                                        label="ServiceProviderForm.address"
                                        value={edited.address}
                                        required={true}
                                        onChange={v => this.updateAttribute("address", v)}

                                    />
                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.phoneNumber" field={
                                <Grid item xs={3} className={classes.item}>
                                    <TextInput
                                        model="serviceProvider"
                                        label="ServiceProviderForm.phoneNumber"
                                        value={edited.phoneNumber}
                                        required={true}
                                        onChange={v => this.updateAttribute("phoneNumber", v)}

                                    />
                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.email" field={
                                <Grid item xs={3} className={classes.item}>
                                    <TextInput
                                        model="serviceProvider"
                                        label="ServiceProviderForm.email"
                                        value={edited.email}
                                        required={true}
                                        onChange={v => this.updateAttribute("email", v)}

                                    />
                                </Grid>
                            } />

                            <ControlledField module="serviceProvider" id="ServiceProvider.email" field={
                                <Grid item xs={3} className={classes.item}>
                                    <TextInput
                                        model="serviceProvider"
                                        label="ServiceProviderForm.fax"
                                        value={edited.fax}
                                        required={true}
                                        onChange={v => this.updateAttribute("fax", v)}
                                    />
                                </Grid>
                            } />


                        </Grid>
                    </Paper>

                </Grid>

                <br />

                {withTooltip(

                    <div className={classes.fab} variant="contained" style={{ display: 'flex', justifyContent: 'center' }}>
                       
                        <Fab color="primary" onClick={this.saveSPData}>

                            <Save />
                        </Fab>
                        {/* <ToastContainer /> */}

                    </div>, formatMessage(intl, "serviceProvider", "addNewServiceProvidert")

                )

                }


            </div>

        )
    }
}

const mapStateToProps = (state, props) => ({
    submittingMutation: state.serviceProvider.submittingMutation,
    mutation: state.serviceProvider.mutation,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createServiceProvider, journalize }, dispatch);
};


// export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateServiceProvider)) );

export default withHistory(withModulesManager(connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(withTheme(withStyles(styles)(CreateServiceProvider))
    ))));