import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fab, Grid, Paper, Typography, Divider } from "@material-ui/core";
import {
  journalize,
  TextInput,
  PublishedComponent,
  FormattedMessage,
} from "@openimis/fe-core";
import { savePrograms } from "../actions";
import { Save } from "@material-ui/icons";

const styles = (theme) => ({
  paper: theme.paper.paper,
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

class AddProgramsPage extends Component {
  state = {
    state_edited: {},
    divcontainer: false,
    edited: {},
  };

  componentDidMount() {
    if (this.props.edited) {
      this.setState((state, props) => ({ state_edited: props.edited }));
    }
  }

  componentDidUpdate(prevPops, prevState, snapshort) {
    if (prevPops.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  savePr = (e) => {
    let insuree = this.state.state_edited.insuree;
    let expAmount = this.props.edited.amount;
    const programs = {
      insuree,
      expAmount,
      scheme: this.props.edited,
    };
    this.props.savePrograms(programs, `Added Insuree to Scheme`);
    window.location.reload();
  };

  updateAttributePr = (k, v) => {
    this.setState((state) => ({
      state_edited: { ...state.state_edited, [k]: v },
    }));
  };

  render() {
    const {
      intl,
      classes = useStyles(),
      title = "Programs.title",
      titleParams = { label: "" },
      readOnly = true,
      family_uuid,
      actions,
    } = this.props;
    const { state_edited, edited } = this.state;

    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container className={classes.tableTitle}>
                <Grid item xs={3} className={classes.tableTitle}>
                  <Typography>
                    <FormattedMessage
                      module="programs"
                      id={title}
                      values={titleParams}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container className={classes.item}>
                <Grid item xs={4} className={classes.item}>
                  <PublishedComponent
                    pubRef="insuree.InsureePicker"
                    value={state_edited.insuree}
                    onChange={(v) => this.updateAttributePr("insuree", v)}
                    required={true}
                  />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                  <TextInput
                    module="scheme"
                    label="schemeForm.amount"
                    value={edited.amount}
                    required={false}
                    onChange={(v) => this.updateAttributePr("expAmount", v)}
                  />
                </Grid>
              </Grid>
              <div
                className={classes.fab}
                variant="contained"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Fab color="primary" onClick={this.savePr}>
                  <Save />
                </Fab>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  submittingMutation: state.programs.submittingMutation,
  mutation: state.programs.mutation,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ savePrograms, journalize }, dispatch);
};

export default withTheme(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(AddProgramsPage)
  )
);
