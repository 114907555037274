import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    journalize,
    Table,
} from "@openimis/fe-core";

const styles = (theme) => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%",
    },
});

class InsureeDisability extends Component {
    state = {};

    componentDidMount() {
        // this.props.fetchDisabilityforInsuree();
    }

    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }
    }

    render() {
        let headers = ["Code", "Benefit"];
        let itemFormatters = [(b) => b.code, (b) => b.benefits];

        const { intl, classes, InsureeDisability } = this.props;
        return (
            <div className={classes.paper}>
                <Table
                    module="benefits"
                    header="Disability"
                    headers={headers}
                    itemFormatters={itemFormatters}
                    items={InsureeDisability}
                    size={"small"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    submittingMutation: state.benefits.submittingMutation,
    mutation: state.benefits.mutation,
    benefits: state.benefits.benefits,
    InsureeDisability: state.benefits.InsureeDisability,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ journalize}, dispatch);
};

export default withTheme(
    withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InsureeDisability))
);
