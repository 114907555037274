
//Input Constats referiencing to name input displayed on the form..
export const SERVICE_PROVIDER_LEVELS_TYPE = ["C", "D", "H"]
export const SERVICE_PROVIDER_LEGAL_FORMS_TYPE = ["C", "D", "G", "P"]
export const SERVICE_PROVIDER_SUB_LEVELS_TYPE = ["I", "N", "R"]

//130011 in doc ... but in practice...
export const RIGHT_SERVICE_PROVIDER = 2300013;
export const RIGHT_SERVICE_PROVIDER_ADD = 2300014;
export const RIGHT_SERVICE_PROVIDER_EDIT = 2300015;
export const RIGHT_SERVICE_PROVIDER_DELETE = 2300016;
export const RIGHT_SERVICE_PROVIDER_ENQUIRE = 2300013;

//130014 in doc ... but in practice...
export const RIGHT_PAY_POINT = 2300017;
export const RIGHT_PAY_POINT_ADD = 2300018;
export const RIGHT_PAY_POINT_EDIT = 2300019;
export const RIGHT_PAY_POINT_DELETE = 2300020;
export const RIGHT_PAY_POINT_ENQUIRE = 2300013;
