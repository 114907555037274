import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Button,
    Paper,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Divider,
    Tooltip,
    IconButton, Fab
} from "@material-ui/core";

import { TextInput, journalize, PublishedComponent, FormattedMessage, withTooltip, formatMessage,decodeId, } from "@openimis/fe-core";
import { createPayPoint } from "../actions";
// import { Fab } from "@material-ui/core";
import { SaveAlt, Save } from "@material-ui/icons";


// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

//Setup styles to adopt in the page...
const styles = theme => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%"
    },
});


//This class generate the form to Add Paypoints.....
class AddPayPoints extends Component {

    //Setup at state veriable..
    state = {
        edited: {}
    }

    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }
    }


    //This function save data into the database....
    savePPData = e => {
        this.props.createPayPoint(this.state.edited, `Creating ${this.state.edited.paypointName}`)

        //This line gets the value of the name input
        let output = `${this.state.edited.paypointName}`;

        //These conditions throws the toast message....
        if (output === "undefined") {

            let message = <strong><em><h2>Fail To Create Paypoint</h2></em></strong>;
            // toast.error(message, {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'toast-notify',
            //     progressClassName: 'notify-progress-bar',
            //     autoClose: 60000
            // });

        } else {


            let message = <strong><em><h2>Created {this.state.edited.paypointName} Successfully...</h2></em></strong>;
            // toast.success(message, {
            //     position: toast.POSITION.TOP_RIGHT,
            //     className: 'toast-notify',
            //     progressClassName: 'notify-progress-bar',
            //     autoClose: 60000
            // });

        }
    }


    updateAttribute = (k, v) => {
        this.setState((state) => ({
            edited: { ...state.edited, [k]: v }

        }),
        )
    }


    render() {
        const { classes, filters, readOnly, intl,  } = this.props;
        const { edited } = this.state;

        //This part returns the form fields to add Paypoints....
        return (
            <div className={classes.page} >
                <br />
                <br />

                <Grid container className={classes.tableTitle}>
                    <Grid item>
                        <Grid container align="center" justify="center" direction="column" className={classes.fullHeight}>
                            <Grid item>
                                <Typography >
                                    <FormattedMessage module="serviceProvider" id="serviceProvider.PayPointDetailPanel.title" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Divider />

                <Grid container className={classes.item} align="center" justify="center" direction="column">
                    <Paper className={classes.paper}>

                        <Grid container xs={12} className={classes.item}>

                            <Grid item xs={3} className={classes.item}>
                                <TextInput
                                    model="Paypoint"
                                    label="payPointForm.paypointName"
                                    value={edited.paypointName}
                                    required={true}
                                    onChange={v => this.updateAttribute("paypointName", v)}

                                />
                            </Grid>


                            <Grid item xs={3} className={classes.item}>
                                <TextInput
                                    model="Paypoint"
                                    label="payPointForm.paypointCode"
                                    value={edited.paypointCode}
                                    required={true}
                                    inputProps={{
                                        "maxLength": this.codeMaxLength,
                                    }}
                                    onChange={v => this.updateAttribute("paypointCode", v)}

                                />
                            </Grid>

                            <Grid item xs={3} className={classes.item}>
                                <TextInput
                                    model="Paypoint"
                                    label="payPointForm.geolocation"
                                    value={edited.geolocation}
                                    required={true}
                                    onChange={v => this.updateAttribute("geolocation", v)}

                                />
                            </Grid>

                            <Grid item xs={3} className={classes.item}>
                                <PublishedComponent
                                pubRef = "serviceProvider.ServiceProviderPicker"
                                label="Service Provider"
                                value = {edited.serviceProvider}
                                // value = {edited.code +" "+ edited.name}
                                required = {true}
                                // readOnly={true}
                                onChange = {v => this.updateAttribute("serviceProvider", v)}
                                />

                            </Grid>

                            <Grid item xs={12} className={classes.item}>
                                <PublishedComponent
                                    
                                    pubRef="location.DetailedLocation"
                                    withNull={true}
                                    readOnly={readOnly}
                                    value={!edited ? null : edited.location}
                                    onChange={(v) => this.updateAttribute("location", v)}
                                    filterLabels={false}

                                />
                            </Grid>



                        </Grid>


                    </Paper>

                </Grid>
                <br />

                {withTooltip(

                    <div className={classes.fab} variant="contained" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Fab color="primary" onClick={this.savePPData}>

                            <Save />
                        </Fab>
                        {/* <ToastContainer /> */}

                    </div>, formatMessage(intl, "serviceProvider", "addNewPaypoint")

                )

                }

            </div>

        )
    }
}

const mapStateToProps = (state, props) => ({
    submittingMutation: state.serviceProvider.submittingMutation,
    mutation: state.serviceProvider.mutation,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ createPayPoint, journalize }, dispatch);
};

export default withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddPayPoints)));