
export const packages = [
  "@openimis/fe-core",
  "@openimis/fe-home",
  "@openimis/fe-location",
  "@openimis/fe-insuree",
  "@openimis/fe-product",
  "@openimis/fe-admin",
  "@openimis/fe-tools",
  "@openimis/fe-profile",
  "@openimis/fe-alternative_language",
  "@openimis/fe-banks",
  "@openimis/fe-benefits",
  "@openimis/fe-cash_transfer_terminology",
  "@openimis/fe-dia_form",
  "@openimis/fe-payroll",
  "@openimis/fe-programs",
  "@openimis/fe-service_provider",
  "@openimis/fe-exporter"
];


export function loadModules (cfg = {}) {
  return [
    require("@openimis/fe-core").CoreModule(cfg["fe-core"] || {}),
    require("@openimis/fe-home").HomeModule(cfg["fe-home"] || {}),
    require("@openimis/fe-location").LocationModule(cfg["fe-location"] || {}),
    require("@openimis/fe-insuree").InsureeModule(cfg["fe-insuree"] || {}),
    require("@openimis/fe-product").ProductModule(cfg["fe-product"] || {}),
    require("@openimis/fe-admin").AdminModule(cfg["fe-admin"] || {}),
    require("@openimis/fe-tools").ToolsModule(cfg["fe-tools"] || {}),
    require("@openimis/fe-profile").ProfileModule(cfg["fe-profile"] || {}),
    require("@openimis/fe-alternative_language").AlternativeLanguageModule(cfg["fe-alternative_language"] || {}),
    require("@openimis/fe-banks").BanksModule(cfg["fe-banks"] || {}),
    require("@openimis/fe-benefits").BenefitsModule(cfg["fe-benefits"] || {}),
    require("@openimis/fe-cash_transfer_terminology").CashTransferTerminologyModule(cfg["fe-cash_transfer_terminology"] || {}),
    require("@openimis/fe-dia_form").DiaFormModule(cfg["fe-dia_form"] || {}),
    require("@openimis/fe-payroll").PayrollModule(cfg["fe-payroll"] || {}),
    require("@openimis/fe-programs").ProgramsModule(cfg["fe-programs"] || {}),
    require("@openimis/fe-service_provider").ServiceProviderModule(cfg["fe-service_provider"] || {}),
    require("@openimis/fe-exporter").ExporterModule(cfg["fe-exporter"] || {})
  ];

}
