import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import _ from "lodash";
import { withTheme, withStyles } from "@material-ui/core/styles";
import {withModulesManager,
  TextInput, formatMessage,withTooltip
} from "@openimis/fe-core";

import {
    Tooltip,
    IconButton
} from "@material-ui/core";
import { CloudUpload as UploadIcon} from '@material-ui/icons';

const styles = theme => ({
  
});

class BulkImport extends Component {  
  
 bulkImportPayroll = () => {

    const {data, fileName, importType} = this.props;

    // const file = e.target.files[0];

    // const reader = new FileReader();
    // reader.onload = (e) => {
    //   const file = e.target.result;
    //   const lines = file.split(/\r\n|\n/);
    //   const result = [];

    //   const headers = lines[0].split(',');

    //   for(let i=1;i<lines.length;i++){
    //     const obj = {};
    //     const currentline=lines[i].split(",");
    //     for(let j=0;j<headers.length;j++){
    //       obj[headers[j]] = currentline[j];
    //     }
    //     result.push(obj);
    //   }
    //   //return result; //JavaScript object
    //   //return JSON.stringify(result); //JSON
    // }
    // reader.onerror = (e) => alert(e.target.error.name);
    // reader.readAsText(file);
    

 }


  
  render() {  
    const {data, fileName, importType} = this.props;
    return ( 
     // bulk import button

     <Fragment>
     <Tooltip title={formatMessage(this.props.intl, "bulkImport", "bulkImport")}>
      <input type="file" id="file" aria-label="upload csv" onChange={this.bulkImportPayroll} />
     </Tooltip>
   </Fragment>
    );  
  }  
}  

export default withModulesManager(injectIntl(withTheme(
  withStyles(styles)(BulkImport)
)));