import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Tooltip, IconButton} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import {
    journalize,
    Table,
} from "@openimis/fe-core";
import { deleteDisability } from "../actions";

const styles = (theme) => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%",
    },
});

class InsureeDisability extends Component {
    state = {};

    componentDidMount() {
        // this.props.fetchBenefitsforInsuree();
    }

    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }
    }

    render() {
        let headers = ["Code", "Disability",];
        let itemFormatters = [(b) => b.code, (b) => b.disability, (b) =>
            <Grid container wrap="nowrap" spacing="2">
                <Grid item>
                    <Tooltip title={"delete"}>
                        <IconButton size="small" onClick={() => {this.props.deleteDisability(b.id); window.location.reload();}}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>];
        const { intl, classes, InsureeDisability } = this.props;
        const disability = InsureeDisability?.map(b => ({ code: b.disability.code, disability: b.disability.disability, id:b.uuid }))
        return (
            <div className={classes.paper}>
                <Table
                    module="insuree"
                    header="Disability"
                    headers={headers}
                    itemFormatters={itemFormatters}
                    items={disability}
                    size={"small"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    submittingMutation: state.benefits.submittingMutation,
    mutation: state.benefits.mutation,
    disability: state.benefits.disability,
    InsureeDisability: state.insuree.InsureeDisability,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ journalize, deleteDisability }, dispatch);
};

export default withTheme(
    withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InsureeDisability))
);
