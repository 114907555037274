import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core";
import {
  withModulesManager,
  decodeId,
  formatDateFromISO,
  journalize,
  coreConfirm,
  Searcher,
} from "@openimis/fe-core";
import { fetchPayrollcycles, deletePayroll } from "../actions";
import {
  formatMessage,
  formatMessageWithValues,
  PublishedComponent,
} from "@openimis/fe-core";
import { SchemeLabel } from "../utils";
// import { IconButton } from "@material-ui/core";
// import {RIGHT_SERVICE_PROVIDER_DELETE } from "../constants";ss

class PayrollCycleList extends Component {
  //Initialize state
  state = {
    page: 0,
    pageSize: 10,
    afterCursor: null,
    confirmedAction: null,
    beforeCursor: null,
  };

  //Define some parameters and pass it while fetching payroll from actions
  query = () => {
    let prams = [];
    prams.push(`first: ${this.state.pageSize}`);
    if (!!this.state.afterCursor) {
      prams.push(`after: "${this.state.afterCursor}"`);
    }
    if (!!this.state.beforeCursor) {
      prams.push(`before: "${this.state.beforeCursor}"`);
    }
    prams.push(`orderBy: ["scheme"]`);
    this.props.fetchPayrollcycles(prams, this.props.onlyGenerated);
  };
  //this method is binding to PayrollcyclesPage class and it is executed every time this page is called
  componentDidMount() {
    //calls the query method to fetch payroll
    this.query();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
      this.setState({ reset: this.state.reset + 1 });
    } else if (!prevProps.confirmed && this.props.confirmed) {
      this.state.confirmedAction();
    }
  }

  //This function is trigged when the row per page droupdown change which sets the pageSize and recall the query
  onChangeRowsPerPage = (cnt) => {
    this.setState(
      { pageSize: cnt, page: 0, afterCursor: null, beforeCursor: null },
      (e) => this.query()
    );
  };

  //This function is trigged on next and previous page change
  onChangePage = (page, nbr) => {
    if (nbr > this.state.page) {
      this.setState(
        (state, props) => ({
          page: state.page + 1,
          beforeCursor: null,
          afterCursor: props.payrollcyclesPageInfo.endCursor,
        }),
        (e) => this.query()
      );
    } else if (nbr < this.state.page) {
      this.setState(
        (state, props) => ({
          page: state.page - 1,
          beforeCursor: props.payrollcyclesPageInfo.startCursor,
          afterCursor: null,
        }),
        (e) => this.query()
      );
    }
  };

  handleDelete = (payrollCycle) => {
    this.props.deletePayroll(
      payrollCycle.uuid,
      payrollCycle.scheme,
      "Payroll Cycle Deleted"
    );
  };

  confirmDelete = (i) => {
    console.log("Payroll", i);

    let confirmedAction = () =>
      this.props.deletePayroll(i.uuid, i.scheme, "Payroll Cycle Deleted");
    let confirm = (e) =>
      this.props.coreConfirm(
        formatMessageWithValues(this.props.intl, "programs", "Delete Payroll", {
          label: SchemeLabel(i),
        }),
        formatMessageWithValues(
          this.props.intl,
          "programs",
          "deleteSchemeDialog.message",
          {
            label: SchemeLabel(i),
          }
        )
      );
    this.setState({ confirmedAction }, confirm);
  };

  headers = (filters) => {
    let headers = [
      "payroll.payrollcycles.scheme_name",
      "payroll.payrollcycles.payrollCycleName",
      "payroll.payrollcycles.start",
      "payroll.payrollcycles.end",
      "Action",
    ];
    headers.push(null);
    return headers;
  };

  itemFormatters = (filters) => {
    const { intl } = this.props;

    let formatters = [
      (e) => e.scheme.name,
      (e) => e.payrollCycleName,
      (e) => e.startDate.split("-").reverse().join("-"),
      (e) => e.endDate.split("-").reverse().join("-"),
      (e) => (
        <IconButton onClick={() => this.confirmDelete(e)}>
          <DeleteIcon />
        </IconButton>
      ),
    ];
    formatters.push();
    return formatters;
  };

  render() {
    const {
      intl,
      fetchingPayrollcycles,
      fetchedPayrollcycles,
      errorPayrollcycles,
      payrollcycles,
      payrollcyclesPageInfo,
      onlyGenerated,
      onDoubleClick,
    } = this.props;

    // let count = ServiceProvidersListInfo.totalCount;

    return (
      <Fragment>
        <Searcher
          module="payroll"
          fetch={this.props.fetchPayrollcycles}
          cacheFiltersKey="payrollCycleList"
          items={payrollcycles}
          rowIdentifier={this.rowIdentifier}
          rowLocked={this.rowLocked}
          itemsPageInfo={payrollcyclesPageInfo}
          fetchingItems={fetchingPayrollcycles}
          fetchedItems={fetchedPayrollcycles}
          errorItems={errorPayrollcycles}
          tableTitle={formatMessageWithValues(
            intl,
            "payroll",
            "PayrollcyclesPage.TableHeader",
            { count: payrollcyclesPageInfo.totalCount }
          )}
          rowsPerPageOptions={this.rowsPerPageOptions}
          defaultPageSize={this.defaultPageSize}
          headers={this.headers}
          itemFormatters={this.itemFormatters}
          rowDisabled={this.rowDisabled}
          onDoubleClick={onDoubleClick}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  rights:
    !!state.core && !!state.core.user && !!state.core.user.i_user
      ? state.core.user.i_user.rights
      : [],
  submittingMutation: state.payroll.submittingMutation,
  mutation: state.payroll.mutation,
  confirmed: state.core.confirmed,
  payrollcycles: state.payroll.payrollcycles,
  payrollcyclesPageInfo: state.payroll.payrollcyclesPageInfo,
  fetchingPayrollcycles: state.payroll.fetchingPayrollcycles,
  fetchedPayrollcycles: state.payroll.fetchedPayrollcycles,
  errorPayrollcycles: state.payroll.errorPayrollcycles,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchPayrollcycles, coreConfirm, journalize, deletePayroll },
    dispatch
  );
};

export default withModulesManager(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(PayrollCycleList))
);
