import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Fab,
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Save } from "@material-ui/icons";
import {
  TextInput,
  journalize,
  PublishedComponent,
  FormattedMessage,
  Table,
  formatMessage,
  historyPush,
} from "@openimis/fe-core";
import { createBenefits, fetchBenefits } from "../actions";
import AddBenefits from "../components/AddBenefits";

const styles = (theme) => ({
  paper: theme.paper.paper,
  tableTitle: theme.table.title,
  item: theme.paper.item,
  fullHeight: {
    height: "100%",
  },
});

class BenefitsPage extends Component {
  state = {
    state_edited: {},
    state_editedB: {},
  };

  componentDidMount() {
    this.props.fetchBenefits();
  }

  componentDidUpdate(prevPops, prevState, snapshort) {
    if (prevPops.submittingMutation && !this.props.submittingMutation) {
      this.props.journalize(this.props.mutation);
    }
  }

  saveBenefits = (e) => {
    this.props.createBenefits(this.state.state_editedB, `Create Benefits`);
  };

  updateAttribute = (k, v) => {
    this.setState((state) => ({
      state_edited: { ...state.state_edited, [k]: v },
    }));
  };

  updateAttributeB = (k, v) => {
    this.setState((state) => ({
      state_editedB: { ...state.state_editedB, [k]: v },
    }));
  };

  // doubleClick = (b) => {
  //   historyPush(
  //     this.props.modulesManager,
  //     this.props.history,
  //     "preauthorization.route.preauthorizationadd",
  //     [p.uuid],
  //     newTab
  //   );
  // };

  render() {
    let headers = ["Code", "Benefit"];
    let itemFormatters = [(b) => b.code, (b) => b.benefits];
    
    const { intl, classes, benefits } = this.props;
    return (
      <div className={classes.page}>
        <AddBenefits />
        <div className={classes.paper}>
          <Table
            module="benefits"
            header="Benefits"
            headers={headers}
            itemFormatters={itemFormatters}
            items={benefits}
            size={"small"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  submittingMutation: state.benefits.submittingMutation,
  mutation: state.benefits.mutation,
  benefits: state.benefits.benefits,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { createBenefits, journalize, fetchBenefits },
    dispatch
  );
};

export default withTheme(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BenefitsPage))
);
