import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { Fab, Modal, Box, Typography, TextField, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { historyPush, withModulesManager, withHistory, withTooltip, formatMessage } from "@openimis/fe-core";
import InsureeSearcher from "../components/InsureeSearcher";

import { RIGHT_INSUREE_ADD } from "../constants";

const styles = (theme) => ({
  page: theme.page,
  fab: theme.fab,
});

class InsureesPage extends Component {
  state = {
    isModalOpen: false, // State to control modal visibility
    selectedFile: null, // State to handle the file upload
  };

  onDoubleClick = (i, newTab = false) => {
    historyPush(this.props.modulesManager, this.props.history, "insuree.route.insuree", [i.uuid], newTab);
  };

  onAdd = (isGroupAdd = false) => {
    if (isGroupAdd) {
      this.setState({ isModalOpen: true });
    } else {
      historyPush(this.props.modulesManager, this.props.history, "insuree.route.insuree");
    }
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, selectedFile: null });
  };

  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  handleFileUpload = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      // Handle the file upload logic here
      console.log("File uploaded:", selectedFile);

      // Close the modal after successful file upload
      this.closeModal();
    }
  };

  render() {
    const { intl, classes, rights } = this.props;
    const { isModalOpen, selectedFile } = this.state;

    return (
      <div className={classes.page}>
        <InsureeSearcher cacheFiltersKey="insureeInsureesPageFiltersCache" onDoubleClick={this.onDoubleClick} />
        {rights.includes(RIGHT_INSUREE_ADD) &&
          withTooltip(
            <div className={classes.fab}>
              <Fab color="primary" onClick={() => this.onAdd(false)}>
                <AddIcon />
              </Fab>
            </div>,
            formatMessage(intl, "insuree", "addNewInsureeTooltip"),
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rights: !!state.core && !!state.core.user && !!state.core.user.i_user ? state.core.user.i_user.rights : [],
});

export default injectIntl(
  withModulesManager(withHistory(connect(mapStateToProps)(withTheme(withStyles(styles)(InsureesPage))))),
);
