import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { useTranslations, useGraphqlQuery } from "@openimis/fe-core";

export default function InsureeDisabilityPicker(props) {
  const [searchString, setSearchString] = useState();
  const { formatMessage } = useTranslations("insuree");
  const onChange = props.onChange;

  const { isLoading, data, error } = useGraphqlQuery(
    `
    query {
      insureeDisability{
        id
        code
        disability
        uuid
      }
    }
  `,
    { str: searchString },
  );
  return (
    <Autocomplete
      multiple
      options={data?.insureeDisability?.map((d) => d) ?? []}
      getOptionLabel={(o) => o?.disability}
      disableCloseOnSelect
      isLoading={isLoading}
      onChange={(option, value) => props.onChange(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={formatMessage("Insuree.disabilities")}
          placeholder={formatMessage("Insuree.disabilities")}
        />
      )}
    />
  );
}
