import { Grid } from "@material-ui/core";
import { PublishedComponent, TextInput } from "@openimis/fe-core";
import React from "react";

const CreditPostPaymentRegion = (props) => {
  const { values, setValues, readOnly } = props;



  return (
    <Grid container direction="column" spacing={1}>
      <Grid>
        <PublishedComponent
          pubRef="payroll.DropDownPayrollCyclePicker"
          value={values.payrollCycleName}
          label="Payroll Cycle"
          onChange={(payrollCycleName) => setValues({ ...values, payrollCycleName})}
        />
      </Grid>
      {/* <Grid item>
        <PublishedComponent
          pubRef="payroll.CyclePicker"
          value={values.payrollCycleName}
          label="Pauroll"
          onChange={(payrollCycleName) => setValues({ ...values, payrollCycleName })}
        />
      </Grid> */}
    </Grid>

   
  );
};

export default CreditPostPaymentRegion;



