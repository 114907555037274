import React, { Component } from "react";
import { withTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Tooltip, IconButton} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import {
    journalize,
    Table,
} from "@openimis/fe-core";
import { fetchBenefitsforInsuree, deleteBenefit } from "../actions";

const styles = (theme) => ({
    paper: theme.paper.paper,
    tableTitle: theme.table.title,
    item: theme.paper.item,
    fullHeight: {
        height: "100%",
    },
});

class InsureeBenefits extends Component {
    state = {};

    componentDidMount() {
        // this.props.fetchBenefitsforInsuree();
    }

    componentDidUpdate(prevPops, prevState, snapshort) {
        if (prevPops.submittingMutation && !this.props.submittingMutation) {
            this.props.journalize(this.props.mutation);
        }
    }

    render() {
        let headers = ["Code", "Benefit",];
        let itemFormatters = [(b) => b.code, (b) => b.benefits, (b) =>
            <Grid container wrap="nowrap" spacing="2">
                <Grid item>
                    <Tooltip title={"delete"}>
                        <IconButton size="small" onClick={() => {this.props.deleteBenefit(b.id); window.location.reload();}}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>];
        const { intl, classes, InsureeBenefits } = this.props;
        const benefits = InsureeBenefits?.map(b => ({ code: b.benefits.code, benefits: b.benefits.benefits, id:b.uuid }))
        return (
            <div className={classes.paper}>
                <Table
                    module="insuree"
                    header="Benefits"
                    headers={headers}
                    itemFormatters={itemFormatters}
                    items={benefits}
                    size={"small"}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    submittingMutation: state.benefits.submittingMutation,
    mutation: state.benefits.mutation,
    benefits: state.benefits.benefits,
    InsureeBenefits: state.insuree.InsureeBenefits,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ journalize, deleteBenefit }, dispatch);
};

export default withTheme(
    withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InsureeBenefits))
);
